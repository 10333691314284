import Vue from "vue";
import VueRouter from "vue-router";
import Store from "@/store";
import Login from "@/views/Login/index.vue";

import Welcome from "@/views/Welcome/index.vue";
import Landing from "@/views/Landing/index.vue";

import Root from "@/views/Root.vue";

import Dashboard from "@/views/Operations/Dashboard.vue";
import Invoices from "@/views/Operations/Invoices.vue";
import InvoicesReceivable from "@/views/Operations/InvoicesReceivable.vue";
import Invoice from "@/views/Operations/Invoice.vue";
import Forwards from "@/views/Operations/Forwards.vue";
import ActiveForward from "@/views/Operations/ActiveForward.vue";
import Balance from "@/views/Operations/Conversions.vue";
import ConversionDetail from "@/views/Operations/Conversion.vue";

import Conversions from "@/views/History/Conversions.vue";
import Conversion from "@/views/History/Conversion.vue";
import Payments from "@/views/History/Payments.vue";
import Payment from "@/views/History/Payment.vue";
import PaymentsIn from "@/views/History/PaymentsIn.vue";
import PaymentIn from "@/views/History/PaymentIn.vue";

import Statement from "@/views/Wallets/AccountStatement.vue";
import Suppliers from "@/views/Wallets/Suppliers.vue";
import Clients from "@/views/Wallets/Clients.vue";
import Security from "@/views/Settings/Security.vue";
import Incomes from "@/views/History/Incomes.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    component: () => import("@/views/Login/index.vue"),
  },
  {
    path: "/home",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "Root",
    component: Root,
    redirect: {
      name: "Landing", // Cambiado de Dashboard a Landing
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/invoices",
        name: "Invoices",
        component: Invoices,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/invoicesReceivable",
        name: "InvoicesReceivable",
        component: InvoicesReceivable,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/invoices/:idInvoice",
        name: "Invoice",
        component: Invoice,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/forwards",
        name: "Forwards",
        component: Forwards,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/forwards/:idActiveForward",
        name: "ActiveForward",
        component: ActiveForward,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/balance",
        name: "Balance",
        component: Balance,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/balance/:idConversion",
        name: "Conversion",
        component: ConversionDetail,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/conversions",
        name: "Conversions",
        component: Conversions,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/conversions/:idConversion",
        name: "Conversion",
        component: Conversion,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/payments",
        name: "Payments",
        component: Payments,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/paymentsIn",
        name: "PaymentsIn",
        component: PaymentsIn,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/paymentsIn/:idBilling",
        name: "PaymentIn",
        component: PaymentIn,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/payments/:idPayment",
        name: "Payment",
        component: Payment,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/suppliers",
        name: "Suppliers",
        component: Suppliers,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/clients",
        name: "Clients",
        component: Clients,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/statement",
        name: "Statement",
        component: Statement,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/security",
        name: "Security",
        component: Security,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/incomes",
        name: "Incomes",
        component: Incomes,
        meta: {
          requiresAuth: true,
        },
      },
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  // When opening a new route send the user to the default location (top left)
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (Store.state.sToken) {
      if (to.name === "Login") {
        next({ name: "Root" }); // Renderiza el componente "Home"
      }
      next();
    } else {
      next({
        path: "/login",
      });
    }
  } else {
    if (Store.state.sToken) {
      if (to.name === "Login") {
        next({ name: "Root" }); // Renderiza el componente "Home"
      }
      next();
    } else {
      next();
    }
  }
});

export default router;
