var texts = {
  en: {
    about: {
      welcome: 'Why PROPLAT?',
      description: "PROPLAT offers ag-tech business services, connecting Mexican growers with buyers in the United States, integrating produce transactions, logistics, financing, and insurance; all in the same platform, removing intermediaries and maximizing profits in a safe and reliable way.",
      platformTitle: 'Platform',
      platformSection: 'Enjoy our produce platform, our solution provides different tools to simplify key aspects of the industry.',
      insuranceTitle: 'Safe',
      insuranceSection: 'We guarantee safe transactions by having certified sellers and buyers, we use advanced security measures in transactions, maintain a reliable infrastructure, and establish user protection policies.',
      logisticTitle: 'Logistics',
      logisticSection: 'We manage logistics efficiently through a step-by-step process designed to provide a seamless experience for our users.',
      financeTitle: 'Financing',
      financeSection: 'We offer affordable financing options through a process that includes online application, approval based on credit evaluation, and the ability to choose flexible payment plans.',
    },
    banner: {
      titleSection: 'The connection between the currency world and produce',
      subtitleSection: 'Control your international transactions without fees and enjoy our platform.',
      buttonBuyer: 'Start now',
      buttonProvider: 'Become a supplier',
    },
    buyer: {
      titleSection: 'Your Strategic Ally for all your produce purchases.',
      subtitleSection: 'We are your complete solution to optimize the purchasing process through reliable suppliers.',
      Catalog: 'Catalogs',
      Warehouses: 'Warehouses',
      Suppliers: 'Suppliers',
      Orders: 'Orders',
      Dispute: 'Dispute History',
      User: 'User Profile',
      buttonBuy: 'I want to buy',
    },
    provider: {
      titleSection: 'Become a supplier and start multiplying your sales',
      subtitleSection: 'In the produce industry, the demand for high-quality fresh products never rests, and you can be part of this growing trend as a supplier at PROPLAT.',
      Products: 'My Products',
      Purchase_orders: 'Purchase Orders',
      Dispute: 'Disputes',
      Warehouses: 'Warehouses',
      Users: 'Users',
      Profile: 'User Profile',
      buttonSell: 'I want to sell',
    },
    AboutLanding: {
      Title: "About Us",
      Subtitle: "Fresher is a financial dashboard specifically designed for the produce industry, created by leaders who understand the day-to-day challenges. This platform aims to provide peace of mind for your business amidst daily currency fluctuations. The relationship between FX and produce plays a crucial role in the success of businesses within this industry, and we are ready to support you."
    },
    Why: {
      Title: "Why FRESHER?",
      Subtitle: "Analyze, control, and decide when is the best time to transact currencies.",
      TC1: "24/7 CURRENCY TRANSACTION",
      TC2: "INTERNATIONAL PAYMENTS WITHOUT FEES",
      TC3: "FINANCING REQUEST",
      TC4: "INFORMATION AND ANALYSIS FOR DECISION-MAKING",
    },
    RegisterBanner: {
      R1: 'Convert and pay instantly!',
      R2: 'Manage all your payments in one tool',
      R3: 'Analyze your movements and their performance to make the best decisions',
      BT: 'Create your account now',
    },
    FooterLanding: {
      central: 'FRESHER, the platform where you can make payments with different currencies to suppliers, as well as keep track of your earnings.',
      TC: 'Terms and Conditions',
      central2: 'All rights reserved FRESHER',
      Created: 'Created by FRESHER',
      home: 'HOME',
      aboutUs: 'ABOUT US',
      login: 'LOGIN',
      register: 'SIGN UP'
    },
    general: {
      privacy: 'Privacy Policy',
      terms: 'Terms and Conditions',
      sections: 'Sections',
      social: 'Social Media',
      download: 'Download',
      home: 'Home',
      aboutUs: 'About Us',
      buyers: 'Buyers',
      providers: 'Suppliers',
    }
  },
  sp: {
    about: {
      welcome: '¿Por qué PROPLAT?',
      description: "Conectamos a agricultores mexicanos con compradores en Estados Unidos, ofreciendo una plataforma integral que facilita la compraventa de productos agrícolas. Nuestra solución abarca aspectos clave como logística, financiamiento y seguros, eliminando intermediarios y garantizando una máxima rentabilidad de manera segura y confiable.",
      platformTitle: 'Plataforma',
      platformSection: 'Disfruta de nuestras plataformas especialmente desarrolladas para el sector del produce, diseñadas para simplificar el proceso de compra y venta de productos.',
      insuranceTitle: 'Seguro',
      insuranceSection: 'Garantizamos la seguridad al contar con vendedores y compradores certificados, utilizamos medidas avanzadas de seguridad en transacciones, mantenemos una infraestructura confiable y establecemos políticas de protección al usuario.',
      logisticTitle: 'Logística',
      logisticSection: 'Gestionamos la logística de manera eficiente mediante un proceso paso a paso diseñado para brindar una experiencia fluida a nuestros usuarios.',
      financeTitle: 'Financiamiento',
      financeSection: 'Ofrecemos opciones de financiamiento económico a través de un proceso que incluye la solicitud en línea, la aprobación basada en la evaluación crediticia y la elección de planes de pago flexibles.',
    },
    banner: {
      titleSection: 'La conexión entre el mundo de las divisas y el produce',
      subtitleSection: 'Controla tus transacciones internacionales sin comisiones y disfruta de nuestra plataforma.',
      buttonBuyer: 'Comenzar ahora',
      buttonProvider: 'Conviértete en proveedor',
    },
    buyer: {
      titleSection: 'Tu Aliado Estratégico para Compras en la Industria del Produce',
      subtitleSection: 'Nos convertimos en tu solución completa para optimizar el proceso de compras a través de proveedores confiables.',
      Catalog: 'Catálogos',
      Warehouses: 'Almacenes',
      Suppliers: 'Proveedores',
      Orders: 'Pedidos',
      Dispute: 'Historial de disputas',
      User: 'Perfil de Usuario',
      buttonBuy: 'Quiero comprar',
    },
    provider: {
      titleSection: 'Conviértete en vendedor y comienza a multiplicar tus ingresos',
      subtitleSection: 'En la industria del produce, la demanda de productos frescos de alta calidad nunca se detiene, y tú puedes ser parte de esta creciente tendencia como un vendedor en PROPLAT.',
      Products: 'Mis artículos',
      Purchase_orders: 'Órdenes de compra',
      Dispute: 'Disputas',
      Warehouses: 'Almacenes',
      Users: 'Usuarios',
      Profile: 'Perfil de Usuario',
      buttonSell: 'Quiero vender',
    },
    AboutLanding: {
      Title: "Sobre nosotros",
      Subtitle: "Fresher es un dashboard financiero específicamente para la industria del produce creada por líderes que entienden los desafíos diarios. Esta plataforma está diseñada para darte tranquilidad a tu negocio en medio de las fluctuaciones cambiarias diarias. La relación entre el FX y el produce juega un papel crucial en el éxito de los negocios dentro de esta industria, y estamos listos para apoyarte."
    },
    Why: {
      Title: "¿Por qué FRESHER?",
      Subtitle: "Analiza, controla y decide cuándo es el mejor momento para transaccionar divisas.",
      TC1: "TRANSACCIÓN DE DIVISAS 24/7",
      TC2: "PAGOS INTERNACIONALES SIN COMISIONES",
      TC3: "SOLICITUD DE FINANCIAMIENTO",
      TC4: "INFORMACIÓN Y ANÁLISIS PARA TOMA DE DECISIONES",
    },
    RegisterBanner: {
      R1: '¡Convierte y paga al instante!',
      R2: 'Lleva el control de tus pagos en una misma herramienta',
      R3: 'Analiza tus movimientos y sus rendimientos para tomar las mejores decisiones',
      BT: 'Crea tu cuenta ahora',
    },
    FooterLanding: {
      central: 'FRESHER, la plataforma donde puedes realizar pagos con diferentes divisas a proveedores, así como llevar un control de las ganancias obtenidas.',
      TC: 'Términos y condiciones',
      central2: 'Todos los derechos reservados FRESHER',
      Created: 'Creado por FRESHER',
      home: 'INICIO',
      aboutUs: 'NOSOTROS',
      login: 'INGRESAR',
      register: 'REGISTRARME'
    },
    general: {
      privacy: 'Aviso de privacidad',
      terms: 'Términos y condiciones',
      sections: 'SECCIONES',
      social: 'REDES SOCIALES',
      download: 'Descarga',
      home: 'Inicio',
      aboutUs: 'Nosotros',
      LogIn: 'Ingresar',
      Register: 'Registrarme',
      buyers: 'COMPRADORES',
      providers: 'PROVEEDORES',
    }
  }
};

export default texts;