var texts = {
    en: {
        dataTable: {
            purchaseDate: 'Purchase date',
            provider: 'Supplier',
            invoice: 'Invoice',
            totalAmount: 'Total amount',
            creditPeriod: 'Credit period',
            dueDate: 'Due date',
            exchangeRateProtection: 'Exchange rate protection',
            exchangeRateToday: 'Exchange rate today',
            currencyProfit: 'Currency profit (%)',
            totalProtectedUsd: 'Total protected (USD)',
            totalUsdToday: 'Total USD today',
            currencyGain: 'Currency gain',
            currencyProfitUsd: 'Currency profit (USD)',
            actions: 'Actions',
            quote: 'Quote',
            status: 'Status',
            add: 'Add',
            addFounds: 'Add founds',
            edit: 'Edit',
            receipt: 'Receipt',
        },
        modalForm: {
            textTitle: 'Modify',
            purchaseDate: 'Purchase date',
            salesDate: 'Sales date',
            provider: 'Supplier',
            invoice: 'Invoice',
            totalAmount: 'Total amount',
            creditPeriod: 'Credit period',
            dueDate: 'Due date',
            exchangeRateProtection: 'Exchange rate protection',
            currencyProfit: 'Currency profit (%)',
            currencyProfitUsd: 'Currency profit (USD)',
            totalProtectedUsd: 'Total protected (USD)',
            currencyGain: 'Currency gain',
            exchangeRateToday: 'Exchange rate today',
            totalUsdToday: 'Total USD today',
            adjust: 'Adjust',
            confirmPay: 'Confirm payment',
            confirmCollect: 'Confirm collection',
            confirmConvert: 'Confirm conversion',
            confirmForward: 'Confirm forward',
            continue: 'Continue',
            cancel: 'Cancel',
            save: 'Save',
            return: 'Return',
            requestPayment: 'Request payment',
            createSupplier: 'Create supplier',
            createClient: 'Create client',
            validate: 'Validate',
            delete: 'Delete',
        },
        textTitle: 'Modify',
        textTitleSepare: 'Quote',
        textTitleCreate: 'Create',
        register: {
            register: 'Register',
            sBankName: 'Bank name',
            sAccountNumber: 'Account number',
            sRoutingNumber: 'Routing number',
            clabe: 'Clabe',
            sBankNameMxn: 'Bank name (MXN)',
        },
        registerProvider: {
            email: 'Email',
            name: 'Name',
            country: 'Country',
            bank_name: 'Bank name',
            clabe: 'Clabe number',
        },
        status: {
            TO_PAY: 'To pay',
            COMPLETED: 'Paid',
            SEPARATED: 'Separated',
            WAITING_FOR_DEPOSIT: 'Waiting for deposit',
            VALIDATION_AMOUNT: 'Amount validation',
            REJECTED: 'Rejected',
            PENDING_TO_PROCESS: 'Processing',
            PROCESSING: 'Processing',
            UNKNOWN_STATUS: 'Unknown status',
            PENDING_TO_PROCESS_SCHEDULED_PAYMENT: 'Pending to process scheduled payment',
        },
        payments: {
            payNow: 'Pay Now',
            payNowDescription: 'Use your account balance',
            paySeparate: 'Convert payment to MXN',
            paySeparateDescription: "Convert your USD to MXN, then choose when to pay your supplier",
            payDeferred: 'Deferred Payment',
            payDeferredDescription: 'Lock an exchange rate, then pay via bank transfer',
            payForward: 'Forward Payment',
            payForwardDescription: 'Guard against exchange rate fluctuations',
        },
        menu: {
            home: 'Home',
            operation: 'Operation',
            history: 'History',
            wallets: 'Contacts',
            providers: 'Providers',
            settings: 'Settings',
        },
        subMenu: {
            dashboard: 'Dashboard',
            history: 'Invoice tracking',
            activeForwards: 'Active forwards',
            balance: 'Available balance MXN',

            historyIn: 'Income History',
            historyConversion: 'Conversion History',
            historyOut: 'Payment History',

            suppliers: 'Suppliers',
            clients: 'Clients',
            statement: 'Statement',

            security: 'Security',
        },
        twoFactor: {
            twoFactor: 'Two-factor authentication',
            enterCode: 'Please enter the 6-digit code sent to your email to confirm the transaction. The code will expire in 10 minutes.',
        },
        dashboard: {
            welcomeTitle: 'Hi, ',
            dashboardDescription: 'Pay your invoices and add funds when you want.',
            exchangeRateActual: 'Actual exchange rate',
            exchangeRateTodayDescription: 'Exchange rate will update in',
            seconds: 'seconds',
            usdBalance: 'USD Balance',
            blockedAmount: 'Blocked amount forwards:',
            addFounds: 'Add funds',
            addFoundsDetail: 'Detail',
            mxnBalance: 'MXN Balance',
            sendPay: 'Send payment',
            collectionPay: 'Whitdrawal',
            sendPayDetail: 'Select the contact you wish to send the payment to.',
            contacts: 'Contacts',
            transferMoney: 'Transfer money',
            client: 'Client',
            balanceAccount: 'Account balance',
            amountToSend: 'How much do you want to send?',
            amountToConvert: 'Amount to convert',
            totalConverted: 'Total converted',
            exchangeRate: 'Exchange rate',
            sendAmount: 'How much would you like to send?',
            hintText: 'You can only convert ${amount} USD.',
            toMexicanPesos: 'To Mexican pesos',
            clientName: 'Client name',
            clabeNumber: 'CLABE number',
            destinationReceives: 'The recipient will receive',
            sendDate: 'Send date',
            paymentConcept: 'Payment concept',

            convert: 'Convert',
            convertAmount: 'Convert the amount you want to a different currency.',
            fromUsdDollars: 'From USD Dollars',
            selectDateForward: 'Select the date of your forward',
            forward: 'Forward',
            buyForward: 'Buy a forward',
            forwardConditions: 'To schedule a future payment, you must meet the following two conditions:',
            forwardConditions1: 'The selected date is the date in which the scheduled payment will be executed. The funds must be loaded before the end of the period.',
            forwardConditions2: 'You will need to make a deposit of 5% of the total Forward funds, which will be deducted from your USD balance.',
            forwardDetail: 'Forward detail',
            amountToReceive: 'Amount to receive in your account:',
            scheduledDate: 'Scheduled date:',
            forwardPercentage: '5% of the total Forward funds',
            forwardConditions3: 'Please load the corresponding funds before or on the due date',
            forwardConditions3TextPart1: 'If the payment does not have the funds for the date indicated: ',
            forwardConditions3TextPart2: 'In case of not meeting the payment of the scheduled operation, the differential between the exchange rate of that date and the initially scheduled rate will have to be covered.',

            pendingInvoices: 'Pending invoices to assign',
            availableConversions: 'Available conversions',
            activeForwards: 'Active forwards',
            massivePayments: 'Massive payments',
            logout: 'Logout',
            version: 'Version',
            allRightsReserved: 'All rights reserved',
            forwardTime: 'The forwards can only be processed between 9 a.m. and 4 p.m. (CDMX, GMT-6), by banking hours',
            payForwardDescriptionDisabled: 'The forwards can only be processed between 9 a.m. and 4 p.m. (CDMX, GMT-6), by banking hours',
            bankingTime: 'CDMX, GMT-6 time',
            localTime: 'Local time',
        },
        invoices: {
            title: 'Pending invoices',
            subTitle: 'Here you will find your invoices that have not been assigned the exchange rate and are pending to be assigned.',
            exchangeRateActual: 'Actual exchange rate',
            exchangeRateTodayDescription: 'Exchange rate will update in',
            seconds: 'seconds',
            invoicePendingToAssign: 'Invoice pending to assign',
            selectSupplier: 'Select supplier',
            selectClient: 'Select client',
            selectStatus: 'Select the status',
            addInvoice: 'Add invoice',
            pay: 'Pay',
            collect: 'Collect',
            convert: 'Convert',
            supplier: 'Supplier',
            purchaseDate: 'Purchase date',
            salesDate: 'Sales date',
            dueDate: 'Due date',
            invoice: 'Invoice',
            status: 'Status',
            totalAmountMXN: 'Total amount MXN',
            rateProtection: 'Exchange rate protection MXN',
            profitPercentage: 'Currency profit (%)',
            currencyGain: 'Currency gain',
            actions: 'Actions',
            selectId: 'Search by PO',
            totalCurrencyGain: 'Total currency gain',
            //Add invoice
            addInvoice: 'Add invoice',
            addInvoiceDetail: 'Add invoice detail',
            addInvoiceDetailText: 'Add the following information to add a new invoice.',
            invoice: 'Invoice',
            supplier: 'Supplier',
            client: 'Client',
            purchaseDate: 'Purchase date',
            salesDate: 'Sales date',
            totalAmountMXN: 'Total amount MXN',
            totalAmountUSD: 'Total amount USD',
            creditTime: 'Credit time',
            exchangeRateProtected: 'Exchange rate protected',
            requiredField: '*indicates required field',
            paymentDetail: 'Payment detail',
            collectDetail: 'Collection detail',
            customerName: 'Supplier name',
            clientName: 'Client name',
            clabe: 'CLABE number',
            amountToSend: 'Amount to send',
            destinationReceives: 'The recipient will receive',
            exchangeRate: 'Exchange rate',
            invoiceToPay: 'Invoice to pay',
            invoiceToCollect: 'Invoice to collect',
            sendDate: 'Send date',
            selectTypeConversion: 'Select the type of conversion',
            conversionDetail: 'Conversion detail',
            invoiceToConvert: 'Invoice to convert',
            amountToReceive: 'Amount to receive in your account',
            exchangeRate: 'Exchange rate',
            convert: 'Convert',
            selectDate: 'Select the date of your forward',
            forwardDetail: 'Forward detail',
            forwardConditions: 'To schedule a future payment, you must meet the following two conditions:',
            forwardConditions1: 'The selected date is the date in which the scheduled payment will be executed. The funds must be loaded before the end of the period.',
            forwardConditions2: 'You will need to make a deposit of 5% of the total Forward funds, which will be deducted from your USD balance.',
            forwardConditions3: 'Please load the corresponding funds before or on the due date',
            forwardConditions4: 'If the payment does not have the funds for the date indicated',
            forwardConditions5: 'In case of not meeting the payment of the scheduled operation, the differential between the exchange rate of that date and the initially scheduled rate will have to be covered.',
            forwardConditions6: 'You will have to pay a deposit of 5% of the total funds of the Forward right now, the amount to deposit is',
            forwardConditions7: 'the amount will be deducted from your USD balance',
            download: 'Download',
            editInvoice: 'Edit invoice',
            invoiceDeleteTitle: 'Are you sure you want to delete the invoice?',
            invoiceDeleteMessage: 'Once you delete the invoice, you will not be able to recover it.',
            sId: "Purchase Order",
            invoiceDuplicateTitle: 'Important notice',
            invoiceDuplicateSubtitle: 'A similar invoice to the one you are trying to add was found, do you want to continue?',
            amountError: 'The amount must be greater than 5% of the total amount.',
        },
        conversions: {
            title: 'Available balance MXN',
            description: 'Here you will find your available conversions to assign invoices or to execute payments.',
            balance: 'MXN Balance',
            totalCurrencyGain: 'Total currency gain',
            pay: 'Pay',
            collect: 'Collect',
            id: 'Id',
            transactionDate: 'Transaction date',
            typeTransaction: 'Type transaction',
            dueDate: 'Due date',
            missingDays: 'Missing days',
            totalAmountUSD: 'Total amount USD',
            totalAmountMXN: 'Total amount MXN',
            exchangeRate: 'Exchange rate',
            invoice: 'Invoice',
            supplier: 'Supplier name',
            purchaseDate: 'Purchase date',
            currencyProfit: 'Currency profit',
            dollarProfit: 'Currency profit',
            actions: 'Actions',
            percentAssigned: '% Assigned',
            selectContact: 'Select the contact to whom you want to send the payment.',
            contacts: 'Contacts',
            paymentDetail: 'Payment detail',
            collectDetail: 'Collection detail',
            clientName: 'Client name',
            clabe: 'CLABE number',
            amountToSend: 'Amount to send',
            destinationReceives: 'The recipient will receive',
            exchangeRate: 'Exchange rate',
            invoiceToPay: 'Invoice to pay',
            invoiceToCollect: 'Invoice to collect',
            sendDate: 'Send date',
            paymentConcept: 'Payment concept',
            detail: 'Detail of the conversion',

            conversionDetailTitle: 'Conversion detail',
            conversionDetailSubTitle: 'Here you will find the detail of the conversion of the selected invoice.',
            conversionDetailType: 'Type',
            conversionDetailAmount: 'Amount',
            conversionDetailProfit: 'Profit',
            conversionDetailProvider: 'Supplier: ',
            conversionDetailExchangeRate: 'Exchange rate',
            conversionDetailDate: 'Creation date',
            conversionDetailDateEnd: 'Date of fulfillment',
            conversionDetailPercent: 'Percentage of the conversion used',
            conversionDetailInvoices: 'Assigned invoices',
            conversionDetailSupplier: 'Supplier name',
            conversionDetailAddInvoices: 'Add invoices',
            conversionDetailInvoice: 'Invoice',
            conversionDetailEditInvoice: 'Modify invoice',
            conversionDetailDeleteInvoice: 'Delete invoice from conversion',
            conversionDetailDatePurchase: 'Purchase date',
            conversionDetailExchangeRateProtected: 'Exchange rate protected',
            conversionDetailConfirmPayment: 'Are you sure you want to request the total payment of the forward?',
            conversionDetailMessage: 'Once you make the transaction, it will be reviewed that your USD balance is sufficient to pay the total amount of the forward.',
            conversionDetailMessage2: 'The amount will go to the Available balance MXN tab.',
            editForward: 'Edit forward',
            requestPayment: 'Request payment',
            payInvoices: 'Pay invoices',
            paymentDate: 'Payment date',
            cobroDate: 'Collection date',
            recipient: 'Recipient',
            client: 'Client',
            download: 'Download',
            status: 'Payment status',
        },
        activeForwards: {
            title: 'Active forwards',
            subTitle: 'Here you will find your active forwards that are pending to be executed.',
            usdBalance: 'USD Balance',
            blockedAmount: 'Blocked amount forwards',
            totalCurrencyGain: 'Total currency gain',
            dueDate: 'Due date',
            missingDays: 'Missing days',
            totalAmountUSD: 'Total amount USD',
            totalAmountMXN: 'Total amount MXN',
            exchangeRate: 'Exchange rate',
            currencyProfit: 'Currency profit',
            supplier: 'Supplier',
            actions: 'Actions',
            invoice: 'Invoice',
            purchaseDate: 'Purchase date',
            dollarProfit: 'Currency profit',
            id: 'Id',
            transactionDate: 'Transaction date',
            typeTransaction: 'Type transaction',
            conversionDetailTitle: 'Conversion detail',
            conversionDetailSubTitle: 'Here you will find the detail of the conversion of the selected invoice.',
            conversionDetailType: 'Type',
            conversionDetailAmount: 'Amount',
            conversionDetailProfit: 'Profit',
            conversionDetailProvider: 'Supplier: ',
            conversionDetailExchangeRate: 'Exchange rate',
            conversionDetailDate: 'Creation date',
            conversionDetailDateEnd: 'Date of fulfillment',
            conversionDetailPercent: 'Percentage of the conversion used',
            conversionDetailInvoices: 'Assigned invoices',
            conversionDetailSupplier: 'Supplier name',
            conversionDetailAddInvoices: 'Add invoices',
            conversionDetailInvoice: 'Invoice',
            conversionDetailEditInvoice: 'Modify invoice',
            conversionDetailDeleteInvoice: 'Delete invoice from conversion',
            conversionDetailDatePurchase: 'Purchase date',
            conversionDetailExchangeRateProtected: 'Exchange rate protected',
            conversionDetailConfirmPayment: 'Are you sure you want to request the total payment of the forward?',
            conversionDetailMessage: 'Once you make the transaction, it will be reviewed that your USD balance is sufficient to pay the total amount of the forward.',
            conversionDetailMessage2: 'The amount will go to the Available balance MXN tab.',
            editForward: 'Edit forward',
            requestPayment: 'Request payment',
            download: 'Download',
            purchaseOrder: 'Purchase Order',
            forwardsAmountPendingGood: 'Don\'t have forwards pending to be executed in the next 5 days',
            forwardsAmountPendingAlert: 'You have forwards pending to be executed in the next 5 days, the total amount is',
            amountRemainingUSD: 'Amount remaining USD',
        },
        conversionsHistory: {
            title: 'Conversion history',
            subTitle: 'Here you will find the history of all your conversions.',
            totalCurrencyGain: 'Total currency gain',
            viewDetail: 'View detail',
            download: 'Download',
            detail: 'Detail of conversion',
            type: 'Type',
            amount: 'Amount',
            exchangeRate: 'Exchange rate',
            creationDate: 'Creation date',
            completionDate: 'Completion date',
            percentageConversionPaid: 'Percentage of conversion paid',
            payments: 'Payments made',
            paymentDate: 'Payment date',
            cobroDate: 'Collection date',
            recipient: 'Recipient',
            invoice: 'Invoice',
            noPayments: 'No payments made',
            percentageConversionUsed: 'Percentage of conversion used',
            invoicesAssigned: 'Invoices assigned',
            invoiceDetail: 'Invoice detail',
            purchaseDate: 'Purchase date',
            exchangeRateProtected: 'Exchange rate protected',
            profitPercent: 'Profit (%)',
            profit: 'Profit',
            noInvoicesAssigned: 'No invoices assigned',
        },
        paymentsHistory: {
            title: 'Payment history',
            subTitle: 'Here you will find the history of all your payments.',
            totalCurrencyGain: 'Total currency gain',
            viewDetail: 'View detail',
            download: 'Download',
        },
        suppliers: {
            title: 'Suppliers',
            titleClient: 'Clients',
            subTitle: 'Here you will manage all your suppliers.',
            subTitleClient: 'Here you will manage all your clients.',
            addSupplier: 'Add supplier',
            addClient: 'Add client',
            name: 'Name',
            email: 'Email',
            bankName: 'Bank name',
            accountNumber: 'Clabe number',
            taxId: 'RFC',
            actions: 'Actions',
            editSupplier: 'Edit supplier',
            deleteSupplier: 'Delete supplier',
            //Create supplier
            createSupplier: 'Create supplier',
            createClient: 'Create client',
            requiredField: '*indicates required field',
            //Edit supplier
            editSupplier: 'Edit supplier',
            download: 'Download'
        },
        settings: {
            title: '2FA (Two-Factor Authentication) is a third-party means to secure your account against intruders and fraud. It is important to keep your authentication application accessible at all times.',
            subTitle: 'Secure your account',
            subTitle2: 'Enable Two-Factor Authentication in your account to keep it safe and secure.',
            emailDescription: 'Use your email to generate verification codes.',
            configure: 'Set up',
            authenticator: 'Authentication app',
            authenticatorDescription: 'Use an authentication app to generate verification codes.',
            sms: 'SMS',
            smsDescription: 'Receive verification codes via text messages.',
            editEmail: 'Edit email',
            configureEmail: 'Set up email',
            twofactor: 'Two-Factor Authentication',
            newEmail: 'New email',
            password: 'Password',
            confirmEmail: 'Confirm your previous email with the OTP code to update your email.',
            confirmPassword: 'Confirm your password to add the email.',
            sendCode: 'Send code',
            newEmailError: 'The new email cannot be equal to the current email.',
            newEmailConfig: 'Enter the new email',
            otpCode: 'Enter the OTP code',
            currentEmail: 'Current email',
            confirmEmail: 'Confirm your previous email with the OTP code to update your email.',
            resetPassword: 'Reset password',
            confirmPassword: 'Confirm your password',
            resetPasswordDescription: 'To reset your password, enter your email and you will receive a verification code to create a new password.',
            resetPasswordButton: 'Reset password',

            users: 'Users',
            usersDescription: 'Here you will manage all your users.',
        },
        incomes: {
            id: 'ID',
            title: 'Income history',
            subTitle: 'Here you will find the history of all your incomes.',
            download: 'Download',
            incomeDate: 'Income date',
            amountUsd: 'Amount USD',
        },
    },
    sp: {
        dataTable: {
            purchaseDate: 'Fecha de compra',
            provider: 'Proveedor',
            invoice: 'Factura',
            totalAmount: 'Monto total',
            creditPeriod: 'Plazo de crédito',
            dueDate: 'Fecha de vencimiento',
            exchangeRateProtection: 'Protección de tipo de cambio',
            exchangeRateToday: 'Tipo de cambio hoy',
            currencyProfit: 'Ganancia de moneda (%)',
            currencyProfitUsd: 'Ganancia de moneda (USD)',
            totalProtectedUsd: 'Total protegido (USD)',
            totalUsdToday: 'Total USD hoy',
            currencyGain: 'Ganancia de moneda',
            actions: 'Acciones',
            quote: 'Cotizar',
            status: 'Estado',
            add: 'Agregar',
            addFounds: 'Agregar fondos',
            edit: 'Editar',
            receipt: 'Recibo',
        },
        modalForm: {
            textTitle: 'Modificar',
            purchaseDate: 'Fecha de compra',
            salesDate: 'Fecha de venta',
            provider: 'Proveedor',
            invoice: 'Factura',
            totalAmount: 'Monto total',
            creditPeriod: 'Plazo de crédito',
            dueDate: 'Fecha de vencimiento',
            exchangeRateProtection: 'Protección de tipo de cambio',
            currencyProfit: 'Ganancia de moneda (%)',
            currencyProfitUsd: 'Ganancia de moneda (USD)',
            totalProtectedUsd: 'Total protegido (USD)',
            currencyGain: 'Ganancia de moneda',
            exchangeRateToday: 'Tipo de cambio hoy',
            totalUsdToday: 'Total USD hoy',
            adjust: 'Ajustar',
            confirmPay: 'Confirmar pago',
            confirmCollect: 'Confirmar cobro',
            confirmConvert: 'Confirmar conversión',
            confirmForward: 'Confirmar forward',
            continue: 'Continuar',
            cancel: 'Cancelar',
            save: 'Guardar',
            return: 'Regresar',
            requestPayment: 'Solicitar pago',
            createSupplier: 'Crear proveedor',
            createClient: 'Crear cliente',
            validate: 'Validar',
            delete: 'Eliminar',
        },
        textTitle: 'Modificar',
        textTitleSepare: 'Cotizar',
        textTitleCreate: 'Crear',
        register: {
            register: 'Registrar',
            sBankName: 'Nombre del banco',
            sAccountNumber: 'Número de cuenta',
            sRoutingNumber: 'Número de ruta',
            clabe: 'Clabe',
            sBankNameMxn: 'Nombre del banco (MXN)',
        },
        registerProvider: {
            email: 'Email',
            name: 'Nombre',
            bank_name: 'Nombre del banco',
            clabe: 'Número de clabe',
        },
        status: {
            TO_PAY: 'Por pagar',
            COMPLETED: 'Pagado',
            SEPARATED: 'Separado',
            WAITING_FOR_DEPOSIT: 'Esperando depósito',
            VALIDATION_AMOUNT: 'Validación de monto',
            REJECTED: 'Rechazado',
            PROCESSING: 'Procesando',
            PENDING_TO_PROCESS: 'Procesando',
            UNKNOWN_STATUS: 'Estado desconocido',
            PENDING_TO_PROCESS_SCHEDULED_PAYMENT: 'Pendiente de procesar pago programado',
        },
        payments: {
            payNow: 'Pagar Ahora',
            payNowDescription: 'Utiliza tu saldo',
            paySeparate: 'Convierte pago a MXN',
            paySeparateDescription: "Convierte tus USD a MXN, luego decide cuándo pagar a tu proveedor",
            payDeferred: 'Pago Diferido',
            payDeferredDescription: 'Asegura un tipo de cambio, después paga por transferencia',
            payForward: 'Pago a Futuro',
            payForwardDescription: 'Protégete de fluctuaciones cambiarias',
        },
        menu: {
            home: 'Inicio',
            operation: 'Operación',
            history: 'Historial',
            wallets: 'Contactos',
            providers: 'Proveedores',
            settings: 'Ajustes',
        },
        subMenu: {
            dashboard: 'Dashboard',
            history: 'Seguimiento de facturas',
            activeForwards: 'Forwards activos',
            balance: 'Saldo disponible MXN',

            historyIn: 'Historial de ingresos',
            historyConversion: 'Historial de conversiones',
            historyOut: 'Historial de egresos',
            suppliers: 'Proveedores',
            clients: 'Clientes',
            statement: 'Estado de cuenta',

            security: 'Seguridad',
        },
        twoFactor: {
            twoFactor: 'Autenticación de dos factores',
            enterCode: 'Por favor, ingresa el código de 6 dígitos que se ha enviado a tu email para confirmar la transacción. El código expirará en 10 minutos.',
        },
        dashboard: {
            welcomeTitle: '¡Hola, ',
            dashboardDescription: 'Paga tus facturas y añade fondos cuando quieras.',
            exchangeRateActual: 'Tipo de cambio actual',
            exchangeRateTodayDescription: 'Tipo de cambio se actualizará en',
            seconds: 'segundos',
            usdBalance: 'Balance USD',
            blockedAmount: 'Monto bloqueado forwards:',
            addFounds: 'Añadir fondos',
            addFoundsDetail: 'Detalle',
            mxnBalance: 'Balance MXN',
            sendPay: 'Enviar pago',
            collectionPay: 'Retiro',
            sendPayDetail: 'Selecciona el contacto al que quieres enviar el pago.',
            contacts: 'Contactos',
            transferMoney: 'Transferir dinero',
            client: 'Cliente',
            balanceAccount: 'Saldo de la cuenta',
            totalConverted: 'Total convertido',
            amountToSend: '¿Cuánto quieres enviar?',
            amountToConvert: 'Monto a convertir',
            exchangeRate: 'Tipo de cambio',
            sendAmount: '¿Cuánto quieres enviar?',
            hintText: 'Usted solo puede convertir ${amount} USD.',
            toMexicanPesos: 'A Pesos Mexicanos',
            clientName: 'Nombre del cliente',
            clabeNumber: 'Nmero CLABE',
            destinationReceives: 'El destinatario recibirá',
            sendDate: 'Fecha de envío',
            paymentConcept: 'Concepto de pago',

            convert: 'Convertir',
            convertAmount: 'Convierte la cantidad que quieras a una moneda diferente.',
            fromUsdDollars: 'De USD Dolares',
            exchangeRate: 'Tipo de cambio',

            forward: 'Pago futuro',
            buyForward: 'Comprar un forward',
            forwardConditions: 'Para programar un futuro pago, debes cumplir con las siguientes dos condiciones:',
            forwardConditions1: 'La fecha seleccionada es la fecha en la cual se ejecutara el pago pactado. La carga de fondos debe efectuarse previo al fin del plazo.',
            forwardConditions2: 'Tendrás que hacer un deposito del 5% de los fondos totales del Futuro, los cuales serán restados de tu balance USD.',
            forwardDetail: 'Detalle del forward',
            amountToReceive: 'Monto a recibir en tu cuenta:',
            scheduledDate: 'Fecha programada:',
            forwardPercentage: '5% de los fondos totales del Futuro',
            forwardConditions3: 'Por favor carga los fondos correspondientes antes o en la fecha del vencimiento',
            forwardConditions3TextPart1: 'Si el pago no cuenta con los fondos para la fecha indicada: ',
            forwardConditions3TextPart2: 'En caso de no cumplir con el pago de la operación pactada, se deberá cubrir el diferencial entre la tipo de cambio de esa fecha y la pactada inicialmente.',


            pendingInvoices: 'Facturas pendientes de asignar',
            availableConversions: 'Conversiones disponibles',
            activeForwards: 'Forwards activos',
            massivePayments: 'Pagos masivos',
            logout: 'Cerrar sesión',
            version: 'Versión',
            allRightsReserved: 'Todos los derechos reservados',
            forwardTime: 'Los futuros solo se procesan de 9 a.m. a 4 p.m. (CDMX, GMT-6) por horario bancario',
            payForwardDescriptionDisabled: 'Los futuros solo se procesan de 9 a.m. a 4 p.m. (CDMX, GMT-6) por horario bancario',
            bankingTime: 'Horario CDMX, GMT-6',
            localTime: 'Horario local',
        },
        invoices: {
            title: 'Facturas pendientes',
            subTitle: 'Aqui encontraras tus facturas que aun no se ha bloqueado el tipo de cambio y que estan pendientes de asignarse.',
            exchangeRateActual: 'Tipo de cambio actual',
            exchangeRateTodayDescription: 'Tipo de cambio se actualizará en',
            seconds: 'segundos',
            invoicePendingToAssign: 'Facturas pendientes de asignar',
            selectSupplier: 'Selecciona el proveedor',
            selectClient: 'Selecciona el cliente',
            selectStatus: 'Selecciona el estatus',
            addInvoice: 'Agregar factura',
            pay: 'Pagar',
            collect: 'Cobrar',
            convert: 'Convertir',
            supplier: 'Proveedor',
            client: 'Cliente',
            purchaseDate: 'Fecha de compra',
            salesDate: 'Fecha de venta',
            dueDate: 'Fecha de vencimiento',
            invoice: 'Factura',
            status: 'Estado',
            totalAmountMXN: 'Monto total MXN',
            totalAmountUSD: 'Monto total USD',
            totalCurrencyGain: 'Total de ganancias',
            rateProtection: 'Protección de tipo de cambio',
            profitPercentage: 'Beneficio de moneda (%)',
            currencyGain: 'Ganancia de moneda',
            actions: 'Acciones',
            selectId: 'Buscar por PO',
            //Add invoice
            addInvoice: 'Agregar factura',
            addInvoiceDetail: 'Ingresa la siguiente información para agregar una nueva factura.',
            invoice: 'Factura',
            supplier: 'Proveedor',
            purchaseDate: 'Fecha de compra',
            salesDate: 'Fecha de venta',
            totalAmountMXN: 'Total monto MXN',
            creditTime: 'Tiempo del credito',
            exchangeRateProtected: 'Tipo de cambio protegido',
            requiredField: '*indica campo requerido',
            paymentDetail: 'Detalle de pago',
            collectDetail: 'Detalle de cobro',
            customerName: 'Nombre del proveedor',
            clientName: 'Nombre del cliente',
            clabe: 'Número CLABE',
            amountToSend: 'Cantidad a enviar',
            destinationReceives: 'El destinatario recibirá',
            exchangeRate: 'Tipo de cambio',
            invoiceToPay: 'Factura a pagar',
            invoiceToCollect: 'Factura a cobrar',
            sendDate: 'Fecha de envío',
            selectTypeConversion: 'Selecciona el tipo de conversión',
            conversionDetail: 'Detalle de la conversión',
            invoiceToConvert: 'Factura a convertir',
            amountToReceive: 'Cantidad a recibir en tu cuenta',
            exchangeRate: 'Tipo de cambio',
            convert: 'Convertir',
            selectDate: 'Selecciona la fecha de tu Futuro',
            forwardDetail: 'Detalle del Futuro',
            forwardConditions: 'Al programar un Futuro tienes que cumplir con las siguientes dos condiciones:',
            forwardConditions1: 'La fecha seleccionada es la fecha en la cual se ejecutará el pago pactado. La carga de fondos debe efectuarse previo al fin del plazo.',
            forwardConditions2: 'Tendrás que hacer un depósito del 5% de los fondos totales del Futuro, los cuales serán restados de tu balance USD.',
            forwardConditions6: 'Necesitarás disponer el 5% de los fondos totales del Futuro ahora, el monto a depositar es de',
            forwardConditions7: 'el monto se restará de tu balance USD',
            amountToSend: 'Cantidad a enviar',
            sendDate: 'Fecha programada',
            invoiceToConvert: 'Factura a convertir',
            amountToReceive: 'Cantidad a recibir en tu cuenta',
            exchangeRate: 'Tipo de cambio',
            convert: 'Convertir',
            forwardConditions3: 'Por favor carga los fondos correspondientes antes o en la fecha del vencimiento',
            forwardConditions4: 'Si el pago no cuenta con los fondos para la fecha indicada',
            forwardConditions5: 'En caso de no cumplir con el pago de la operación pactada, se deberá cubrir el diferencial entre la tipo de cambio de esa fecha y la pactada inicialmente.',
            download: 'Descargar',
            editInvoice: 'Editar factura',

            invoiceDeleteTitle: '¿Estás seguro de que deseas eliminar esta factura?.',
            invoiceDeleteMessage: 'Una vez que eliminas la factura no podras recuperarla.',
            sId: "Orden de compra",
            invoiceDuplicateTitle: 'Aviso importante',
            invoiceDuplicateSubtitle: 'Se encontro una invoice similar a la que intentas agregar, ¿Deseas continuar?',
            amountError: 'El monto debe ser mayor al 5% del monto total.',
        },
        conversions: {
            title: 'Saldo disponible MXN',
            description: 'Aqui encontraras tus conversiones disponibles para asignar facturas o ejecutar pagos.',
            balance: 'Balance MXN',
            totalCurrencyGain: 'Total de ganancias',
            pay: 'Pagar',
            collect: 'Cobrar',
            id: 'Id',
            transactionDate: 'Fecha de transacción',
            typeTransaction: 'Tipo de transacción',
            dueDate: 'Fecha de vencimiento',
            missingDays: 'Días faltantes',
            totalAmountUSD: 'Monto total USD',
            totalAmountMXN: 'Monto total MXN',
            exchangeRate: 'Tipo de cambio',
            invoice: 'Factura',
            supplier: 'Nombre del proveedor',
            purchaseDate: 'Fecha de compra',
            currencyProfit: 'Ganancia de moneda',
            dollarProfit: 'Ganancia de moneda',
            actions: 'Acciones',
            percentAssigned: '% Asignado',
            selectContact: 'Selecciona el contacto al que quieres enviar el pago.',
            contacts: 'Contactos',
            paymentDetail: 'Detalle de pago',
            collectDetail: 'Detalle de cobro',
            clientName: 'Nombre del cliente',
            clabe: 'Nmero CLABE',
            amountToSend: 'Cantidad a enviar',
            destinationReceives: 'El destinatario recibirá',
            exchangeRate: 'Tipo de cambio',
            invoiceToPay: 'Factura a pagar',
            invoiceToCollect: 'Factura a cobrar',
            sendDate: 'Fecha de envío',
            paymentConcept: 'Concepto de pago',
            detail: 'Detalle de la conversión',

            conversionDetailTitle: 'Detalle de la conversión',
            conversionDetailSubTitle: 'Aqui encontraras el detalle de la conversión de la factura seleccionada.',
            conversionDetailType: 'Tipo',
            conversionDetailAmount: 'Monto',
            conversionDetailProfit: 'Ganancia',
            conversionDetailProvider: 'Proveedor',
            conversionDetailExchangeRate: 'Tipo de cambio',
            conversionDetailDate: 'Fecha de creación',
            conversionDetailDateEnd: 'Fecha de cumplimiento',
            conversionDetailPercent: 'Porcentaje de la conversión utilizado',
            conversionDetailInvoices: 'Facturas asignadas',
            conversionDetailSupplier: 'Nombre del proveedor',
            conversionDetailAddInvoices: 'Agregar facturas',
            conversionDetailInvoice: 'Factura',
            conversionDetailEditInvoice: 'Modificar factura',
            conversionDetailDeleteInvoice: 'Eliminar factura de la conversión',
            conversionDetailPurchaseDate: 'Fecha de compra',
            conversionDetailExchangeRateProtected: 'Tipo de cambio protegido',
            conversionDetailConfirmPayment: '¿Estás seguro de que deseas solicitar el pago total del forward?',
            conversionDetailMessage: 'Una vez que realizas la transacción se revisará que tu saldo USD sea suficiente para pagar el total del forward.',
            conversionDetailMessage2: 'El monto se irá a la pestaña de saldo disponible MXN.',
            editForward: 'Editar forward',
            requestPayment: 'Solicitar pago',
            payInvoices: 'Pagar facturas',
            paymentDate: 'Fecha de pago',
            cobroDate: 'Fecha de cobro',
            recipient: 'Destinatario',
            client: 'Cliente',
            download: 'Descargar',
            status: 'Estado del pago',
        },
        activeForwards: {
            title: 'Forwards activos',
            subTitle: 'Aqui encontraras tus forwards activos de que estan pendientes de realizarse.',
            usdBalance: 'Balance USD',
            blockedAmount: 'Monto bloqueado forwards',
            totalCurrencyGain: 'Total de ganancias',
            dueDate: 'Fecha de vencimiento',
            missingDays: 'Días faltantes',
            totalAmountUSD: 'Monto total USD',
            totalAmountMXN: 'Monto total MXN',
            exchangeRate: 'Tipo de cambio',
            currencyProfit: 'Ganancia de moneda',
            supplier: 'Proveedor',
            actions: 'Acciones',
            invoice: 'Factura',
            purchaseDate: 'Fecha de compra',
            currencyProfit: 'Ganancias de moneda',
            dollarProfit: 'Ganancia de moneda',
            id: 'Id',
            transactionDate: 'Fecha de transacción',
            typeTransaction: 'Tipo de transacción',
            conversionDetailTitle: 'Detalle de la conversión',
            conversionDetailSubTitle: 'Aqui encontraras el detalle de la conversión de la factura seleccionada.',
            conversionDetailType: 'Tipo',
            conversionDetailAmount: 'Monto',
            conversionDetailProfit: 'Ganancia',
            conversionDetailProvider: 'Proveedor',
            conversionDetailExchangeRate: 'Tipo de cambio',
            conversionDetailDate: 'Fecha de creación',
            conversionDetailDateEnd: 'Fecha de cumplimiento',
            conversionDetailPercent: 'Porcentaje de la conversión utilizado',
            conversionDetailInvoices: 'Facturas asignadas',
            conversionDetailSupplier: 'Nombre del proveedor',
            conversionDetailAddInvoices: 'Agregar facturas',
            conversionDetailInvoice: 'Factura',
            conversionDetailEditInvoice: 'Modifiwcar factura',
            conversionDetailDeleteInvoice: 'Eliminar factura de la conversión',
            conversionDetailPurchaseDate: 'Fecha de compra',
            conversionDetailExchangeRateProtected: 'Tipo de cambio protegido',
            conversionDetailConfirmPayment: '¿Estás seguro de que deseas solicitar el pago total del forward?',
            conversionDetailMessage: 'Una vez que realizas la transacción se revisará que tu saldo USD sea suficiente para pagar el total del forward.',
            conversionDetailMessage2: 'El monto se irá a la pestaña de saldo disponible MXN.',
            editForward: 'Editar forward',
            requestPayment: 'Solicitar pago',
            download: 'Descargar',
            purchaseOrder: 'Orden de compra',
            forwardsAmountPendingGood: 'No tienes forwards pendientes de ejecutar en los próximos 5 días',
            forwardsAmountPendingAlert: 'Tienes forwards pendientes de ejecutar en los próximos 5 días, el monto total es de: ',
            amountRemainingUSD: 'Monto restante USD',
        },
        conversionsHistory: {
            title: 'Historial de conversiones',
            subTitle: 'Aqui encontraras el historial de todas tus conversiones realizadas.',
            totalCurrencyGain: 'Total de ganancias',
            viewDetail: 'Ver detalle',
            download: 'Descargar',
            detail: 'Detalle de conversión',
            type: 'Tipo',
            amount: 'Monto',
            exchangeRate: 'Tipo de cambio',
            creationDate: 'Fecha de creación',
            completionDate: 'Fecha de cumplimiento',
            percentageConversionPaid: 'Porcentaje de conversión pagado',
            payments: 'Pagos realizados',
            paymentDate: 'Fecha de pago',
            cobroDate: 'Fecha de cobro',
            recipient: 'Destinatario',
            invoice: 'Factura',
            noPayments: 'No hay pagos realizados',
            percentageConversionUsed: 'Porcentaje de conversión utilizado',
            invoicesAssigned: 'Facturas asignadas',
            invoiceDetail: 'Detalle de la factura',
            purchaseDate: 'Fecha de compra',
            exchangeRateProtected: 'Tipo de cambio protegido',
            profitPercent: 'Profit (%)',
            profit: 'Profit',
            noInvoicesAssigned: 'No hay facturas asignadas',
        },
        paymentsHistory: {
            title: 'Historial de egresos',
            subTitle: 'Aqui encontraras el historial de todos tus pagos realizados.',
            totalCurrencyGain: 'Total de ganancias',
            viewDetail: 'Ver detalle',
            download: 'Descargar',
        },
        suppliers: {
            title: 'Proveedores',
            subTitle: 'Aqui podras administrar todos tus proveedores.',
            addSupplier: 'Agregar proveedor',
            addClient: 'Agregar cliente',
            name: 'Nombre',
            email: 'Correo',
            bankName: 'Nombre de banco',
            accountNumber: 'Número de clabe',
            taxId: 'RFC',
            actions: 'Acciones',
            editSupplier: 'Editar proveedor',
            deleteSupplier: 'Eliminar proveedor',
            //Crear proveeedor
            createSupplier: 'Crear proveedor',
            createClient: 'Crear cliente',
            requiredField: '*indica un campo obligatorio',
            //Editar proveedor
            editSupplier: 'Editar proveedor',
            download: 'Descargar'
        },
        settings: {
            title: '2FA (Two Factor Authentication) es un medio de terceros para asegurar tu cuenta contra intrusos y fraudes. Es importante mantener tu aplicación de autenticación accesible en todo momento.',
            subTitle: 'Asegura tu cuenta',
            subTitle2: 'Habilita la Autenticación de Dos Factores en tu cuenta para mantenerla segura y proteger tu dinero.',
            emailDescription: 'Usa tu correo electrónico para generar códigos de verificación.',
            configure: 'Configurar',
            authenticator: 'Aplicación de autenticador',
            authenticatorDescription: 'Usa una aplicación de autenticación para generar códigos de verificación.',
            sms: 'SMS',
            smsDescription: 'Recibe códigos de verificación a través de mensajes de texto.',
            editEmail: 'Editar correo electrónico',
            configureEmail: 'Configurar correo electrónico',
            twofactor: 'Autenticación de dos factores',
            newEmail: 'Correo electrónico nuevo',
            password: 'Contraseña',
            confirmEmail: 'Confirma tu correo anterior con el código OTP para actualizar tu correo.',
            confirmPassword: 'Confirma tu contraseña para agregar el correo electrónico.',
            sendCode: 'Enviar código',
            newEmailError: 'El correo electrónico nuevo no puede ser igual al actual',
            newEmailConfig: 'Ingresar nuevo correo',
            otpCode: 'Ingresar código OTP',
            currentEmail: 'Correo electrónico actual',
            confirmEmail: 'Confirma tu correo anterior con el código OTP para actualizar tu correo.',
            resetPassword: 'Restablecer contraseña',
            resetPasswordDescription: 'Para restablecer tu contraseña, ingresa tu correo electrónico y recibirás un código de verificación para crear una nueva contraseña.',
            resetPasswordButton: 'Restablecer contraseña',
            confirmPassword: 'Confirma tu contraseña',

            users: 'Usuarios',
            usersDescription: 'Aqui podras administrar todos tus usuarios.',
        },
        incomes: {
            id: 'ID',
            title: 'Historial de ingresos',
            subTitle: 'Aqui encontraras el historial de todos tus ingresos realizados.',
            download: 'Descargar',
            incomeDate: 'Fecha de ingreso',
            amountUsd: 'Monto USD',
        }
    }
};

export default texts;