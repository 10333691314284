<template>
  <v-container class="mt-5">
    <v-row align="center" justify="center">
      <v-col cols="12" md="10">
        <v-skeleton-loader :loading="isLoading" type="card, article, image">
          <template v-slot:default>
            <v-card class="pa-5">
              <v-row no-gutters>
                <v-col cols="12" class="pa-4">
                  <v-row align="center" justify="center" class="mb-5">
                    <v-icon color="#193D6E" size="200">
                      mdi-shield-lock-outline
                    </v-icon>
                  </v-row>
                  <!-- <p class="text-center mt-2">
                    {{ texts.settings.title }}
                  </p> -->
                  <h2 class="text-center">{{ texts.settings.subTitle }}</h2>
                  <!-- <p class="text-center">
                    {{ texts.settings.subTitle2 }}
                  </p> -->
                </v-col>
              </v-row>
              <v-row>
                <!-- Email -->
                <v-col cols="12" md="4">
                  <v-card class="pa-4" outlined>
                    <v-card-title
                      class="headline text-center justify-center align-center"
                    >
                      <v-icon size="70" color="#193D6E">mdi-email</v-icon>
                    </v-card-title>
                    <v-card-title
                      class="headline text-center justify-center align-center"
                      style="word-break: break-word;"
                    >
                      {{ texts.settings.twofactor }}
                    </v-card-title>
                    <v-card-text class="text-center">
                      {{ texts.settings.subTitle2 }}
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="#193D6E" outlined @click="openDialog" block>
                        {{ texts.settings.configure }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <!-- Reset password -->
                <v-col cols="12" md="4">
                  <v-card class="pa-4" outlined>
                    <v-card-title
                      class="headline text-center justify-center align-center"
                    >
                      <v-icon size="70" color="#193D6E">mdi-lock-reset</v-icon>
                    </v-card-title>
                    <v-card-title
                      class="headline text-center justify-center align-center"
                      style="word-break: break-word;"
                    >
                      {{ texts.settings.resetPassword }}
                    </v-card-title>
                    <v-card-text class="text-center">
                      {{ texts.settings.resetPasswordDescription }}
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        color="#193D6E"
                        outlined
                        block
                        @click="openResetPasswordDialog"
                      >
                        {{ texts.settings.resetPasswordButton }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-col>
                <!-- Users -->
                <v-col cols="12" md="4">
                  <v-card class="pa-4" outlined>
                    <v-card-title
                      class="headline text-center justify-center align-center"
                    >
                      <v-icon size="70" color="#193D6E">mdi-account</v-icon>
                    </v-card-title>
                    <v-card-title
                      class="headline text-center justify-center align-center"
                    >
                      {{ texts.settings.users }}
                    </v-card-title>
                    <v-card-text class="text-center">
                      {{ texts.settings.usersDescription }}
                    </v-card-text>
                    <v-card-actions>
                      <v-btn disabled color="primary" block>
                        {{ texts.settings.configure }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </template>
        </v-skeleton-loader>
      </v-col>
    </v-row>

    <v-dialog v-model="configDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">
          {{
            isEmailRegistered
              ? texts.settings.editEmail
              : texts.settings.configureEmail
          }}
        </v-card-title>
        <v-card-text>
          <v-form ref="editForm" v-model="editValid">
            <v-text-field
              v-model="email"
              :rules="emailRules"
              :label="texts.settings.email"
              required
              outlined
              :disabled="isEmailRegistered"
              prepend-icon="mdi-email"
              type="email"
            ></v-text-field>
            <v-text-field
              v-if="isEmailRegistered"
              v-model="newEmail"
              :rules="emailRules"
              :label="texts.settings.newEmail"
              required
              outlined
              prepend-icon="mdi-email"
              type="email"
            ></v-text-field>
            <v-text-field
              v-if="!isEmailRegistered"
              v-model="password"
              :rules="passwordRules"
              :label="texts.settings.password"
              required
              :disabled="
                !email || !emailRules.every((rule) => rule(email) === true)
              "
              outlined
              prepend-icon="mdi-lock"
              type="password"
            ></v-text-field>
            <v-otp-input
              v-model="otp"
              v-if="isEmailRegistered"
              type="number"
              color="#f0be43"
              length="6"
            ></v-otp-input>
            <div v-if="isEmailRegistered">
              <span>{{ texts.settings.confirmEmail }}</span>
            </div>
            <div v-else>
              <span>{{ texts.settings.confirmPassword }}</span>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="configDialog = false">{{
            texts.modalForm.cancel
          }}</v-btn>
          <v-btn
            color="blue darken-1"
            :loading="isFormLoading"
            text
            @click="confirmConfig"
            :disabled="!editValid || (isEmailRegistered && !otp.length === 6)"
          >
            {{
              isEmailRegistered
                ? texts.settings.sendCode
                : texts.settings.configure
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" max-width="500px" persistent>
      <v-card>
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1" color="#193D6E">
              {{ texts.settings.newEmailConfig }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2" color="#193D6E">
              {{ texts.settings.otpCode }}
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card-text>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  :label="texts.settings.currentEmail"
                  required
                  outlined
                  disabled
                  prepend-icon="mdi-email"
                  type="email"
                  @keydown.native="preventSubmit"
                ></v-text-field>
                <v-form ref="editForm" v-model="editValid">
                  <v-text-field
                    v-model="newEmail"
                    :rules="emailRules"
                    :label="texts.settings.newEmail"
                    required
                    outlined
                    prepend-icon="mdi-email"
                    type="email"
                    @keydown.native="preventSubmit"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#193D6E" text @click="editDialog = false">{{
                  texts.modalForm.cancel
                }}</v-btn>
                <v-btn
                  color="#193D6E"
                  text
                  @click="sendOtp"
                  :disabled="!editValid"
                  >{{ texts.modalForm.continue }}</v-btn
                >
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card-text>
                <v-form ref="otpForm" v-model="otpValid">
                  <v-otp-input
                    v-model="otp"
                    :rules="otpRules"
                    type="number"
                    length="6"
                    @keydown.native="preventSubmit"
                  ></v-otp-input>
                </v-form>
                <div>
                  <span>{{ texts.settings.confirmEmail }}</span>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#193D6E" text @click="step = 1">{{
                  texts.modalForm.cancel
                }}</v-btn>
                <v-btn
                  color="#193D6E"
                  text
                  @click="validateOtp"
                  :loading="isFormLoading"
                  :disabled="!otpValid"
                >
                  {{ texts.modalForm.validate }}
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>

    <v-dialog v-model="resetPasswordDialog" max-width="500px" persistent>
      <v-card>
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1" color="#193D6E">
              {{ texts.settings.otpCode }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2" color="#193D6E">
              {{ texts.settings.resetPassword }}
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card-text>
                <v-form ref="otpForm" v-model="otpValid">
                  <v-otp-input
                    v-model="otp"
                    :rules="otpRules"
                    type="number"
                    length="6"
                    @keydown.native="preventSubmit"
                  ></v-otp-input>
                </v-form>
                <div>
                  <span>{{ texts.settings.confirmEmail }}</span>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="#193D6E"
                  text
                  @click="resetPasswordDialog = false"
                  >{{ texts.modalForm.cancel }}</v-btn
                >
                <v-btn
                  color="#193D6E"
                  text
                  @click="validateOtpCode"
                  :loading="isLoadingOtp"
                  :disabled="!otpValid"
                >
                  {{ texts.modalForm.validate }}
                </v-btn>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card-text>
                <v-text-field
                  v-model="password"
                  :rules="passwordRules"
                  :label="texts.settings.password"
                  required
                  outlined
                  prepend-icon="mdi-lock"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>

                <v-text-field
                  v-model="passwordConfirm"
                  :rules="passwordRules"
                  :label="texts.settings.confirmPassword"
                  required
                  outlined
                  :type="showPasswordConfirm ? 'text' : 'password'"
                  :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPasswordConfirm = !showPasswordConfirm"
                  prepend-icon="mdi-lock"
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#193D6E" text @click="step = 1">{{
                  texts.modalForm.cancel
                }}</v-btn>
                <v-btn
                  color="#193D6E"
                  text
                  @click="resetPassword"
                  :loading="isLoadingResetPassword"
                  :disabled="!password || !passwordConfirm"
                  >{{ texts.settings.resetPassword }}</v-btn
                >
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      newEmail: "",
      valid: false,
      step: 1,
      isEmailRegistered: false,
      isLoading: true,
      isLoadingResetPassword: false,
      isFormLoading: false,
      isLoadingOtp: false,
      texts: {},
      emailRules: [
        (v) => !!v || "The email is required",
        (v) => /.+@.+\..+/.test(v) || "The email must be valid"
      ],
      configDialog: false,
      editDialog: false,
      resetPasswordDialog: false,
      password: "",
      passwordConfirm: "",
      showPassword: false,
      showPasswordConfirm: false,
      otp: "",
      editValid: false,
      otpValid: false,
      otpSent: false,
      passwordRules: [
        (v) => !!v || "La contraseña es obligatoria",
        (v) =>
          v.length >= 6 || "La contraseña debe tener al menos 6 caracteres",
        (v) =>
          /[!@#$%^&*(),.?":{}|<>]/.test(v) ||
          "La contraseña debe tener al menos un carácter especial"
      ],
      otpRules: [
        (v) => !!v || "The OTP code is required",
        (v) => /^\d{6}$/.test(v) || "The OTP code must be a 6-digit number"
      ]
    };
  },
  methods: {
    getDataAuth() {
      this.isLoading = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/authenticator/getData`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      })
        .then((response) => {
          if (!response.data.success) {
            this.mixError(response.data.message, response.status);
          }
          this.email = response.data.data.sAuthEmail.sEmail;
          this.isEmailRegistered = response.data.data.sAuthEmail.bEnabled;
          this.resetForm();
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    openDialog() {
      if (this.isEmailRegistered) {
        this.openEditModal();
      } else {
        this.configDialog = true;
      }
    },
    activate2FA() {
      if (this.$refs.form.validate()) {
        this.isEmailRegistered = true;
      }
    },
    openEditModal() {
      this.editDialog = true;
      this.step = 1;
    },
    sendOtp() {
      if (this.$refs.editForm.validate()) {
        if (this.newEmail === this.email) {
          this.mixError(this.texts.settings.newEmailError, 400);
          return;
        }
        this.updateEmailOpt(this.newEmail);
        this.step = 2;
      }
    },
    validateOtp() {
      if (this.$refs.otpForm.validate()) {
        this.updateEmail(this.newEmail, this.otp);
      }
    },
    async validateOtpCode() {
      this.isLoadingOtp = true;
      try {
        const payload = {
          sCode: this.otp
        };

        const response = await DB.post(
          `${URI}/api/v1/${this.selectLanguage}/authenticator/verify`,
          payload,
          {
            headers: {
              Authorization: this.$store.state.sToken
                ? `Bearer ${this.$store.state.sToken}`
                : ""
            }
          }
        );

        if (!response.data.success) {
          this.mixError(response.data.message, response.status);
        }
        this.step = 2;
        this.isLoadingOtp = false;
        return response.data.success;
      } catch (error) {
        console.error("Error in validateOtp:", error);
        return false;
      }
    },
    confirmConfig() {
      if (this.$refs.editForm.validate()) {
        this.isFormLoading = true;
        const payload = {
          sEmail: this.email,
          sPassword: this.password
        };
        DB.post(
          `${URI}/api/v1/${this.selectLanguage}/authenticator/saveEmail`,
          payload,
          {
            headers: {
              Authorization: this.$store.state.sToken
                ? `Bearer ${this.$store.state.sToken}`
                : ""
            }
          }
        )
          .then((response) => {
            if (!response.data.success) {
              this.mixError(response.data.message, response.status);
            }
            this.mixSuccess(response.data.message, response.status);
            this.isFormLoading = false;
            this.getDataAuth();
            this.configDialog = false;
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
            this.isFormLoading = false;
          });
      }
    },
    openResetPasswordDialog() {
      this.resetPasswordDialog = true;
      this.step = 1;
      this.sendVerification();
    },
    sendVerification() {
      try {
        DB.get(`${URI}/api/v1/${this.selectLanguage}/authenticator/`, {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        })
          .then((response) => {
            if (!response.data.success) {
              this.mixError(response.data.message, response.status);
            }
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
      } catch (error) {
        this.mixError(error.response.data.message, error.response.status);
      }
    },
    updateEmailOpt(newEmail) {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/authenticator/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      })
        .then((response) => {
          if (!response.data.success) {
            this.mixError(response.data.message, response.status);
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.isFormLoading = false;
        });
    },
    updateEmail(newEmail, otp) {
      this.isFormLoading = true;
      try {
        DB.put(
          `${URI}/api/v1/${this.selectLanguage}/authenticator/updateEmail`,
          {
            sEmail: newEmail,
            sCode: otp
          },
          {
            headers: {
              Authorization: this.$store.state.sToken
                ? `Bearer ${this.$store.state.sToken}`
                : ""
            }
          }
        )
          .then((response) => {
            if (!response.data.success) {
              this.mixError(response.data.message, response.status);
            }
            this.mixSuccess(response.data.message, response.status);
            this.getDataAuth();
            this.isFormLoading = false;
            this.editDialog = false;
          })
          .catch((error) => {
            this.isFormLoading = false;
            this.mixError(error.response.data.message, error.response.status);
          });
      } catch (error) {
        this.isFormLoading = false;
        this.mixError(error.response.data.message, error.response.status);
      }
    },
    resetPassword() {
      this.isLoadingResetPassword = true;
      const payload = {
        sPassword: this.password,
        sConfirmPassword: this.passwordConfirm
      };

      try {
        DB.put(
          `${URI}/api/v1/${this.selectLanguage}/recovery-passwords`,
          payload,
          {
            headers: {
              Authorization: this.$store.state.sToken
                ? `Bearer ${this.$store.state.sToken}`
                : ""
            }
          }
        )
          .then((response) => {
            if (!response.data.success) {
              this.mixError(response.data.message, response.status);
            }
            this.mixSuccess(response.data.message, response.status);
            this.logout();
            this.isLoadingResetPassword = false;
            this.resetPasswordDialog = false;
          })
          .catch((error) => {
            this.isLoadingResetPassword = false;
            this.mixError(error.response.data.message, error.response.status);
          });
      } catch (error) {
        this.isLoadingResetPassword = false;
        this.mixError(error.response.data.message, error.response.status);
      }
    },
    preventSubmit(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
    resetForm() {
      this.newEmail = "";
      this.password = "";
      this.otp = "";
    },
    logout: function() {
      if (this.$store.state.sToken !== "") {
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };

        DB.delete(`${URI}/api/v1/${this.selectLanguage}/session`, config)
          .then((response) => {
            const self = this;

            localStorage.clear();
            this.$store.commit("setIsLogged", false);
            this.$store.commit("setFadeMenu", false);
            this.$store.commit("setToken", "");
            this.$store.commit("setFullName", "");
            this.$store.commit("setEnterpriseId", "");
            this.$store.commit("setUserId", "");

            setTimeout(function() {
              self.mixSuccess(response.data.message);
            }, 200);
            setTimeout(function() {
              self.$router
                .push({
                  path: "/login",
                })
                .catch(() => {});
            }, 400);
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
      }
    },
  },
  beforeMount() {
    this.texts = FILE.takeRate[this.selectLanguage];
    this.getDataAuth();
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    }
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.takeRate[this.selectLanguage];
      }
    }
  }
};
</script>

<style scoped>
.text-center {
  text-align: center;
}

h2 {
  font-size: 24px;
  font-weight: bold;
}

p {
  font-size: 16px;
}
</style>
