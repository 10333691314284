var texts = {
  en: {
    login: {
      textTitle: 'Welcome!',
      textSubtitle: 'Please enter your email and password to continue.',
      inputEmail: {
        label: 'Email',
        placeholder: 'Email'
      },
      inputPassword: {
        label: 'Password',
        placeholder: '●●●●●●●●'
      },
      buttonLogin: 'Login',
      forgotPassword: "Do you have a problem logging in?",
      proplatAccount: 'Create account',
      buttonReturn: 'Back',
      start: 'Start',
      account: "Don't have an account?",
      rights: 'All rights reserved.',
      privacy: 'Privacy Policy',
      termCond: 'Terms and Conditions'
    }
  },
  sp: {
    login: {
      textTitle: '¡Bienvenido!',
      textSubtitle: 'Por favor, ingresa tu correo electrónico y contraseña para continuar',
      inputEmail: {
        label: 'Correo electrónico',
        placeholder: 'Correo electrónico'
      },
      inputPassword: {
        label: 'Contraseña',
        placeholder: '●●●●●●●●'
      },
      buttonLogin: 'Ingresar',
      forgotPassword: '¿Problemas para iniciar sesión?',
      proplatAccount: 'Crear cuenta',
      buttonReturn: 'Regresar',
      start: 'Empezar',
      account: '¿No tienes una cuenta?',
      rights: 'Todos los derechos reservados.',
      privacy: 'Aviso de Privacidad',
      termCond: 'Términos y Condiciones'
    }
  }
};

export default texts;